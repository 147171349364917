// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casinobonukset-js": () => import("./../../../src/pages/casinobonukset.js" /* webpackChunkName: "component---src-pages-casinobonukset-js" */),
  "component---src-pages-ilmaiskierroksia-js": () => import("./../../../src/pages/ilmaiskierroksia.js" /* webpackChunkName: "component---src-pages-ilmaiskierroksia-js" */),
  "component---src-pages-nettikasinot-js": () => import("./../../../src/pages/nettikasinot.js" /* webpackChunkName: "component---src-pages-nettikasinot-js" */),
  "component---src-pages-pikakasinot-js": () => import("./../../../src/pages/pikakasinot.js" /* webpackChunkName: "component---src-pages-pikakasinot-js" */),
  "component---src-pages-uudet-kasinot-js": () => import("./../../../src/pages/uudet-kasinot.js" /* webpackChunkName: "component---src-pages-uudet-kasinot-js" */),
  "component---src-pages-yhteys-js": () => import("./../../../src/pages/yhteys.js" /* webpackChunkName: "component---src-pages-yhteys-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

